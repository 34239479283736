import {createAsyncThunk} from '@reduxjs/toolkit';
import { ActionTypes } from '../constants/types';
import { apiCall } from '../../apis/api';
import { MerchantChangePassword, MerchantLogin } from '../../models/merchant';


export const merchantLogin = createAsyncThunk(
  ActionTypes.MERCHANT_LOGIN,
  async (params: MerchantLogin, {rejectWithValue}) => {
    try {
      const merchant = await apiCall('api/v1/merchant/auth/login', 'post', params);
      localStorage.setItem('jwtToken', merchant?.data?.access_token);
      localStorage.setItem('merchantName', merchant?.data?.merchant?.name);
      localStorage.setItem('category', merchant?.data?.merchant?.category);
      localStorage.setItem('location', merchant?.data?.merchant?.location || merchant?.data?.merchant?.locations?.data[0]?.address || null);
      localStorage.setItem('merchantEmail', params.email);
      localStorage.setItem('merchantId', merchant?.data?.merchant?.id);
      localStorage.setItem('updatedPassword', merchant?.data?.merchant?.updatedPassword);


      return merchant;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const merchantLogout = createAsyncThunk(
  ActionTypes.MERCHANT_LOGOUT,
  async (_: void, {rejectWithValue}) => {
    try {
      const response = await apiCall('api/v1/merchant/auth/logout', 'post');
      return response;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const merchantChangePassword = createAsyncThunk(
  ActionTypes.MERCHANT_CHANGE_PASSWORD,
  async (params: MerchantChangePassword, {rejectWithValue}) => {
    try {
      const response = await apiCall('api/v1/merchant/auth/change-password', 'post', params);
      return response;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const merchantHome = createAsyncThunk(
  ActionTypes.MERCHANT_HOME,
  async (_: void, {rejectWithValue}) => {
    try {
      const response = await apiCall('api/v1/merchant/home', 'get');
      return response;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getMerchantStats = createAsyncThunk(
  ActionTypes.MERCHANT_STATS,
  async (filter: string, { rejectWithValue }) => {
    try {
      const response = await apiCall(`api/v1/merchant/get-sales-summary?filter=${filter}`, 'get');
      return response;
    } catch (error: any) {
      // Handle errors and return an error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const storePaymentSuccessData = createAsyncThunk(
  ActionTypes.STORE_PAYMENT_SUCCESS_DATA,
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const response = await apiCall(`api/v1/merchant/payment-success?sessionId=${sessionId}`, 'post');
      return response;
    } catch (error: any) {
      // Handle errors and return an error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlaidAndReceipt = createAsyncThunk<
  { plaidData: any[]; receiptData: any[] },
  { isPlaid: boolean; page: number; rowsPerPage: number },
  {
    rejectValue: string;
  }
>(
  ActionTypes.FETCH_PLAID_AND_RECEIPT,
  async ({ isPlaid, page, rowsPerPage }, { rejectWithValue }) => {
    try {
      let url = `api/v1/merchant/my-transactions?perPage=${rowsPerPage}&page=${page}`;
      if (isPlaid) {
        url += '&is_plaid=true';
      }
      const response = await apiCall(url, 'get');
      return response; 
      
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchEwalletData = createAsyncThunk<
  {  eWalletData: any[] },
  {  page: number; rowsPerPage: number },
  {
    rejectValue: string;
  }
>(
  ActionTypes.FECTH_E_WALLET_DATA,
  async ({  page, rowsPerPage }, { rejectWithValue }) => {
    try {
      let url = `api/v1/merchant/my-wallet-transactions?perPage=${rowsPerPage}&page=${page}`;
     
      const response = await apiCall(url, 'get');
      return response; 
      
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchMerchantNotificationsCount = createAsyncThunk(
  ActionTypes.FETCH_MERCHANT_NOTIFICATIONS,
  async () => {
    try {
      const response = await apiCall(
        `api/v1/merchant/my-notifications`,
        "get"
      );
      return response?.notifications?.data?.filter((item:any)=>item.is_read ==0).length;
    } catch (error: any) {
      // Handle errors and return an error message
    }
  }
);