import { Paper, Stack, Typography } from "@mui/material";
import { APP_FONT } from "../constants/AppFont";
import { useSelector } from "react-redux";

const EarnedRevenue = () => {
  const profit = useSelector((state: any) =>
    Math.round(state.merchant.merchant_details?.data?.profit || 0)
  );
  const merchantName = localStorage.getItem("merchantName");
  const merchantEmail = localStorage.getItem("merchantEmail");
  return (
    <>
      <Typography
        fontWeight={700}
        marginBottom={"27px"}
        sx={{ color: "#0E162C", fontFamily: APP_FONT, fontSize: "1rem" }}
      >
        Earned Revenue
      </Typography>
      <Paper elevation={0} sx={{ padding: 2, backgroundColor: "#EAE5F1",wordWrap:"break-word" }}>
        <Stack spacing={2}>
          <Typography fontFamily={APP_FONT} fontWeight={600} fontSize={"1rem"}>
            {merchantName} -  {merchantEmail}
          </Typography>
        
          <Typography fontFamily={APP_FONT} fontWeight={600} fontSize={"1rem"}>
            Revenue
          </Typography>
          <Typography alignSelf={"center"} fontSize={"2rem"} fontWeight={700}>
            ${profit.toFixed(2)}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};

export default EarnedRevenue;
